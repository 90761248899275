import { ApiRequest, Reducer } from 'store';
import { FileAndType } from 'services/FileService';
import { FinancialRequestCount, FinancialRequestDetailed, FinancialRequestSummary, FinancialSortingSource, SortDirection } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';

export const DEFAULT_SEARCH_FINANCIAL_REQUEST_TAKE = 10;

export const DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS = {
    skip: 0,
    take: DEFAULT_SEARCH_FINANCIAL_REQUEST_TAKE,
    sortDirection: SortDirection.Desc,
    sortSource: FinancialSortingSource.InternalReferenceId,
};

export interface SearchFinancialRequest {
    skip: number;
    take: number;
    sortDirection?: SortDirection,
    sortSource?: FinancialSortingSource,
}

export const initialState = {
    financialRequest: {
        isFetching: undefined as boolean | undefined,
    } as ApiRequest,
    count: {} as ApiRequest<FinancialRequestCount>,
    files: [] as FileAndType[],
    validatedFinancialRequest: {} as { [id: number]: ApiRequest<FinancialRequestDetailed> },
    search: {
        filters: {
            ...DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<FinancialRequestSummary[]> & {
        filters: SearchFinancialRequest,
        total: number,
    },
} as const;

export type FinancialState = typeof initialState;

export const sirenState: SirenDictionnaryType<FinancialState> = {};

export const FinancialReducer: Reducer<SirenDictionnaryType<FinancialState>> = (state = sirenState, action) => {
    switch (action.type) {
        case '@FINANCIAL/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                financialRequest: {
                    isFetching: true,
                },
            });

        case '@FINANCIAL/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                financialRequest: {
                    isFetching: false,
                },
            });

        case '@FINANCIAL/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                financialRequest: {
                    isFetching: false,
                },
            });

        case '@FINANCIAL/SET_FILES':
            return sirenStatePatcher(state, action.payload.siren, {
                files: action.payload.files,
            });

        case '@FINANCIAL/CLEAR_FILES':
            return sirenStatePatcher(state, action.payload.siren, {
                files: [],
            });

        case '@FINANCIAL/GET_REQUEST_BY_ID':
            return sirenStatePatcher(state, action.payload.siren, {
                validatedFinancialRequest: {
                    ...state[action.payload.siren]?.validatedFinancialRequest,
                    [action.payload.requestId]: {
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            });

        case '@FINANCIAL/GET_REQUEST_BY_ID_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                validatedFinancialRequest: {
                    ...state[action.payload.siren]?.validatedFinancialRequest,
                    [action.payload.requestId]: {
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.data,
                    },
                },
            });

        case '@FINANCIAL/GET_REQUEST_BY_ID_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                validatedFinancialRequest: {
                    ...state[action.payload.siren]?.validatedFinancialRequest,
                    [action.payload.requestId]: {
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            });
        case '@FINANCIAL/SEARCH_FINANCIAL_REQUEST':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@FINANCIAL/SEARCH_FINANCIAL_REQUEST_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.FinancialRequestsList,
                    total: action.payload.total,
                },
            });

        case '@FINANCIAL/SEARCH_FINANCIAL_REQUEST_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@FINANCIAL/GET_COUNT':
            return sirenStatePatcher(state, action.payload.siren, {
                count: {
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@FINANCIAL/GET_COUNT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                count: {
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.data,
                },
            });

        case '@FINANCIAL/GET_COUNT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                count: {
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@FINANCIAL/SET_SEARCH_FINANCIAL_SORT':

            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS,
                        sortDirection: action.payload.sortDirection,
                        sortSource: action.payload.sortSource,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@FINANCIAL/SET_SEARCH_FINANCIAL_REQUEST_CHANGE_PAGE':
        
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        default:
            return state;
    }
};
