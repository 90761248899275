import { useAppContext } from 'components/common/AppProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { SearchContractRequest } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { track, TrackedPageType } from 'services/Logger';
import { DEFAULT_SEARCH_CONTRACTS_FILTERS } from 'store/Contracts/ContractsReducer';
import { FranfinanceSelectors } from 'store/Normalizr/FranfinanceSelectors';

interface HeaderListProps {
    className?: string,
    toggle?: () => void
}

export const HeaderList: React.FC<HeaderListProps> = ({ className, toggle }) => {
    const { t } = useTranslation();
    const { state: { appContext: { loggedUserId }, contracts, appContext: { currentClient: { siren } }, entities } } = useAppContext();
    const loggedUser = FranfinanceSelectors.getLoggedUser(loggedUserId, entities.franFinance);

    const handleClick = (pageName: string) => {
        track('ClickOnMenu', 'click on menu', { page: TrackedPageType.Menu, pageName });
    };

    const searchFilters = siren && contracts[siren]?.search?.filters || DEFAULT_SEARCH_CONTRACTS_FILTERS as SearchContractRequest;

    return <ul className={className} data-bs-popper="static">
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.Home.url()}
                className="navbar-link"
                onMouseUp={() => handleClick('Home page')}
            >
                {RoutePaths.Home.title(t)}
            </NavLink>
        </li>
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.Contracts.url(searchFilters)}
                className="navbar-link"
                onMouseUp={() => handleClick('Your contracts')}
            >
                {t('menu:Titles.YourContracts')}
            </NavLink>
        </li>
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.Invoices.url()}
                className="navbar-link"
                onMouseUp={() => handleClick('Your billings')}
            >
                {t('menu:Titles.YourBillings')}
            </NavLink>
        </li>
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.CustomerRequests.url()}
                className="navbar-link"
                onMouseUp={() => handleClick('Steps')}
            >
                {t('menu:Titles.Steps')}
            </NavLink>
        </li>
        {loggedUser?.canSeeFinancialRequest
            && <li className="navbar-item">
                <NavLink
                    onClick={toggle}
                    end
                    to={RoutePaths.Simulations.FinancialRequest.url()}
                    className="navbar-link"
                    onMouseUp={() => handleClick('Project')}
                >
                    {t('menu:Titles.Projects')}
                </NavLink>
            </li>
        }
    </ul >;
};